import request from "@/utils/request";
// 发起代充
export function onProxy(data) {
  return request("post", "/apm/proxy/recharge/readyPay", data);
}

// 活动列表
export function proxyList(data) {
  return request("post", "/apm/proxy/recharge/list", data);
}
// 删除优惠活动
export function delProxy(data) {
    return request("post", "/apm/proxy/recharge/del", data);
  }
// 查询支付状态
export function getPayStatus(data) {
  return request("post", "/apm/proxy/recharge/findStatusById", data);
}

